import { graphql } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'

import SEO from '~/components/shared/Seo'
import { AllArticlePageProps } from '~/types/common-props'
import { fileToImageLikeData, getPageMeta } from '~/utils'
import ContactsThanks from '~/views/Thanks/ContactsThanks.view'

import 'react-toastify/dist/ReactToastify.min.css'

const ContactsThanksPage = ({ data }: AllArticlePageProps) => (
  <ContactsThanks data={data} />
)

export default ContactsThanksPage

export const pageQuery = graphql`
  query ($published: [Boolean]) {
    strapiPage(page_name: { eq: "Thanks Contacts" }) {
      ...MetaTagInformationPages
    }

    allStrapiArticle(
      limit: 3
      sort: { createdAt: DESC }
      filter: { active: { eq: true }, published: { in: $published } }
    ) {
      edges {
        node {
          id
          url
          preview_image_alt
          category {
            name
          }
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
              }
            }
          }
          title
          meta_description
          modified_date
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
        }
      }
    }
  }
`

export const Head = ({ data }: AllArticlePageProps) => {
  const { meta_title, meta_description, og_image } = getPageMeta(
    data.strapiPage,
  )

  return (
    <SEO
      title={meta_title}
      description={meta_description}
      urlImage={getSrc(fileToImageLikeData(og_image?.localFile)) as string}
      url="https://www.codica.com/contacts/thanks/"
      meta={[
        {
          name: 'robots',
          content: 'noindex',
        },
      ]}
    />
  )
}
