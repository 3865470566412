import { getImage } from 'gatsby-plugin-image'

import HeadSection from '~/components/blocks/HeadSection'
import RecentArticles from '~/components/blocks/RecentArticles'
import SocialBlock from '~/components/blocks/SocialBlock'
import MainLayout from '~/components/layouts/MainLayout'
import { AllArticlePageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import useContactStaticQuery from '~/views/Contacts/useContactsStaticQuery'
import PrimeSectionLeadMagnets from '~/views/EbookLandings/components/PrimeSectionLeadMagnets'

const ContactsThanks = ({ data }: AllArticlePageProps) => {
  const query = useContactStaticQuery()
  const quoteCodicaCEOPhoto = getImage(
    fileToImageLikeData(query.quoteCodicaCEOPhoto),
  )

  return (
    <MainLayout isTransparent>
      <PrimeSectionLeadMagnets
        title="Thank you for the request!"
        maxWidthTitle="650px"
        isInfo
        infoImage={quoteCodicaCEOPhoto}
      />

      <section style={{ margin: '70px 0 50px' }}>
        <HeadSection
          position="left"
          title="In the meantime, take a look at our most recent articles:"
        />
        <RecentArticles data={data} buttonText="Explore Codica Blog" />
      </section>

      <SocialBlock />
    </MainLayout>
  )
}

export default ContactsThanks
